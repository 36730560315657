import React from "react";

import { base } from "./Statistic.module.scss";

const Statistic = props => {
  const { label, value } = props;

  return (
    <div className={base}>
      <h4>{label}</h4>
      <p>{value}</p>
    </div>
  );
};

export default Statistic;
