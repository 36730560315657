import React from "react";

import { base, caseStudiesContainer } from "./RelatedCaseStudies.module.scss";

import CaseStudyCard from "./CaseStudyCard";

import { combine } from "../utils/classNames";

const RelatedCaseStudies = props => {
  const { className, caseStudies } = props;

  if (!caseStudies || caseStudies.length === 0) {
    return null;
  }

  return (
    <div className={combine(base, className)}>
      <h2>Related case studies</h2>
      <div className={caseStudiesContainer}>
        {caseStudies.map((caseStudy, index) =>
          <CaseStudyCard
            key={index}
            imageSrc={caseStudy.featureImages[0].src}
            imageAlt={caseStudy.featureImages[0].alt}
            heading={caseStudy.title}
            body={caseStudy.content}
            slug={caseStudy.slug}
            activationType={caseStudy.activationType}
          />
        )}
      </div>
    </div>
  );
};

export default RelatedCaseStudies;
