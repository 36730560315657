import React from "react";

import {
  article,
  logoContainer,
  statisticsContainer,
  media,
  embedVideo,
  backgroundBanner,
  quoteContainer,
  contentSection,
  buttonContainer,
} from "./case-study-article.module.scss";

import Layout from "../layouts/Layout";
import ArticleFooter from "../partials/ArticleFooter";
import RelatedCaseStudies from "../partials/RelatedCaseStudies";
import Statistic from "../components/Statistic";
import Slideshow from "../components/Slideshow";
import Quote from "../components/Quote";
import Button from "../components/Button";

import { sanitiseHtml, getVideoSrc } from "../utils/misc";
import { useMediaQuery } from "../utils/hooks";

const CaseStudyArticlePage = props => {
  const {
    pageContext: { caseStudy },
  } = props;

  const isMobile = useMediaQuery("(max-width: 575px)");

  if (!caseStudy) {
    return null;
  }

  return (
    <Layout
      pageTitle="Case Studies | Brandcrush"
      ogTitle={caseStudy.title}
      ogDescription={caseStudy.subtitle}
      ogImage={caseStudy.featureImages[0].src}
    >
      <article className={article}>
        <header>
          <div className={logoContainer}>
            {caseStudy.partnerLogo?.src && (
              <img src={caseStudy.partnerLogo.src} alt={caseStudy.partnerLogo.alt} />
            )}
            {caseStudy.brandLogo?.src && (
              <img src={caseStudy.brandLogo.src} alt={caseStudy.brandLogo.alt} />
            )}
          </div>
          <h4>{caseStudy.activationType}</h4>
          <h1>{caseStudy.title}</h1>
          <p>{caseStudy.subtitle}</p>
          <div className={statisticsContainer}>
            {caseStudy.statistics.map((statistic, index) => (
              <Statistic key={index} label={statistic.label} value={statistic.value} />
            ))}
          </div>
        </header>
        <main>
          <section className={media}>
            <div className={backgroundBanner}></div>
            <Slideshow>
              {[
                caseStudy.featureVideo && (
                  <div
                    className={embedVideo}
                    dangerouslySetInnerHTML={{ __html: caseStudy.featureVideo.src }}
                  ></div>
                ),
                ...caseStudy.featureImages.map((image, index) => (
                  <img key={index} src={image.src} alt={image.alt} />
                )),
              ].filter(x => x)}
            </Slideshow>
            {caseStudy.quotes && caseStudy.quotes.length > 0 && (
              <Slideshow className={quoteContainer} autoscroll={isMobile}>
                {caseStudy.quotes.map((quote, index) => (
                  <Quote key={index} quote={quote.quote} author={quote.author} />
                ))}
              </Slideshow>
            )}
          </section>
          <section
            className={contentSection}
            dangerouslySetInnerHTML={{ __html: sanitiseHtml(caseStudy.content) }}
          ></section>
        </main>
        <ArticleFooter />
        <RelatedCaseStudies caseStudies={caseStudy.relatedCaseStudies} />
        <div className={buttonContainer}>
          <Button color="navy" href="/case-studies">
            View all case studies
          </Button>
        </div>
      </article>
    </Layout>
  );
};

export default CaseStudyArticlePage;
