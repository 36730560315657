import React, { useState, useEffect } from "react";

import {
  base,
  viewPort,
  container,
  itemContainer,
  arrowLeft,
  arrowRight,
} from "./Slideshow.module.scss";

import { combine } from "../utils/classNames";
import { useMediaQuery, useGesture } from "../utils/hooks";

const Slideshow = props => {
  const { className, children, width, autoscroll } = props;

  const [index, setIndex] = useState(0);
  const isMobile = useMediaQuery("(max-width: 575px)");

  const handleLeftClick = () => {
    const newIndex = (index - 1) % children.length;
    setIndex(newIndex >= 0 ? newIndex : children.length - 1);
  };

  const handleRightClick = () => {
    setIndex((index + 1) % children.length);
  };

  const ref = useGesture(handleLeftClick, handleRightClick);

  const hasMultiple = Array.isArray(children) && children.length > 1;

  const translateX = index => isMobile ? index * 100 + "vw" : index * 51.25 + "rem";

  const keyframes = [
    { transform: `translateX(0)`, easing: "ease" },
    ...children.map((_, index) => ({
      transform: `translateX(-${translateX(index)})`,
      easing: "ease",
    })),
  ];

  useEffect(() => {
    const animation = autoscroll && ref.current.animate(keyframes, {
      duration: children.length * 2000,
      iterations: Infinity,
    });
  });

  return (
    <div className={combine(base, className)}>
      {hasMultiple && !isMobile && (
        <img
          src="/images/slideshow-arrow.svg"
          alt="Arrow left"
          className={arrowLeft}
          onClick={handleLeftClick}
        />
      )}
      <div className={viewPort}>
        <div ref={ref} className={container} style={{ transform: `translateX(-${translateX(index)})` }}>
          {children.map((child, index) => (
            <div key={index} className={itemContainer}>
              {child}
            </div>
          ))}
        </div>
      </div>
      {hasMultiple && !isMobile && (
        <img
          src="/images/slideshow-arrow.svg"
          alt="Arrow right"
          className={arrowRight}
          onClick={handleRightClick}
        />
      )}
    </div>
  );
};

export default Slideshow;
